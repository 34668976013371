import clsx from 'clsx';
import React from 'react';

import styles from './Logo.module.scss';
import { LogoProps } from './Logo.types';

export const Logo = ({ logoUrl, className, institutionName }: LogoProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.logoWrapper}>
      <img src={logoUrl} alt={`${institutionName} logo`} className={styles.logo} />
    </div>
  </div>
);
